import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import SEO from '../../../components/seo';
import { Intro } from '../../../components/Intro/Intro';
import { StyledLink } from '../../../components/StyledLink';
import Projects from '../../../components/Projects';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { CardOutbound, CardContainer, CardBody } from '../../../components/Card';
import { designTokens } from '../../../components/Theme/designTokens';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Text';
import ImageIcon from '../../../components/ImageIcon';
import { Button, ButtonOutbound, ButtonGhost } from '../../../components/Button';
import portfolioPDF from '../../../static/Ryan Parag-Product Designer_Portfolio.pdf';
import { motion } from 'framer-motion';
import { Badge } from '../../../components/Badge';
import { List, ListItem, ListInner } from '../../../components/List';
import { Pill } from '../../../components/Pill';
import { RandomizerAbout } from '../../../components/RandomizerAbout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Style Guide" keywords={['Designer', 'UI/UX', 'Tampa']} mdxType="SEO" />
    <Intro initial={{
      y: `-${designTokens.space[5]}`,
      opacity: 0
    }} animate={{
      y: 0,
      opacity: 1
    }} transition={{
      duration: 0.25,
      delay: 0.1
    }} mdxType="Intro">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col mdxType="Col">
        <h2 style={{
              marginTop: '0'
            }}>Style Guide 🎨</h2>
        <p className="lead">Design documentation on the design tokens, components, utilities, and layouts that are used across my portfolio.</p>
        <p>As a documentation nerd, I thought it would not only be fun to showcase the assets that help make this portfolio possible, but also be helpful to do a design inventory and build more efficient design patterns.</p>
      </Col>
    </CSSGrid>
  </Container>
    </Intro>
    <Section section="color" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col mdxType="Col">
        <h3>Color</h3>
        <p>Color can help bring a design to life. Color is versatile; it's used to express emotion and tone, as well as place emphasis and create associations. Color should always be used in meaningful and intentional ways in order to create patterns and visual cues.</p>
        <h5>Primary/State Colors</h5>
        <p>We can use these colors to express state, accent, or emphasis</p>
        <CSSGrid mdxType="CSSGrid">
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <div>
              <Box bg={({
                    theme
                  }) => theme.green} marginBottom={designTokens.space[2]} center mdxType="Box"> Primary
              </Box>
              <Box bg={({
                    theme
                  }) => theme.greenTransparent} marginBottom={designTokens.space[2]} center mdxType="Box"> Primary Transparent
              </Box>
              <Box bg={({
                    theme
                  }) => theme.highlightedText} marginBottom={designTokens.space[2]} center color={({
                    theme
                  }) => theme.grey0} mdxType="Box"> Primary Dark
              </Box>
            </div>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <div>
              <Box bg={({
                    theme
                  }) => theme.pink} marginBottom={designTokens.space[2]} center mdxType="Box"> Secondary
              </Box>
              <Box bg={({
                    theme
                  }) => theme.pinkTransparent} marginBottom={designTokens.space[2]} center mdxType="Box"> Secondary Transparent
              </Box>
              <Box bg={({
                    theme
                  }) => theme.visited} marginBottom={designTokens.space[2]} center color={({
                    theme
                  }) => theme.grey0} mdxType="Box"> Secondary Dark
              </Box>
            </div>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <div>
              <Box bg={({
                    theme
                  }) => theme.blue} marginBottom={designTokens.space[2]} center mdxType="Box"> Tertiary
              </Box>
              <Box bg={({
                    theme
                  }) => theme.blueTransparent} marginBottom={designTokens.space[2]} center mdxType="Box"> Tertiary Transparent
              </Box>
              <Box bg={({
                    theme
                  }) => theme.tertiaryDark} marginBottom={designTokens.space[2]} center color={({
                    theme
                  }) => theme.grey0} mdxType="Box"> Tertiary Dark
              </Box>
            </div>
          </Col>
        </CSSGrid>
        <h5>Neutral Colors</h5>
        <p>We can use these colors to shade the neutral parts of our UI (eg. text, backgrounds, panels, form controls)</p>
        <CSSGrid mdxType="CSSGrid">
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <div>
              <Box bg={({
                    theme
                  }) => theme.grey900} marginBottom={designTokens.space[2]} center color={({
                    theme
                  }) => theme.grey0} mdxType="Box"> Grey 900
              </Box>
              <Box bg={({
                    theme
                  }) => theme.grey800} marginBottom={designTokens.space[2]} center color={({
                    theme
                  }) => theme.grey0} mdxType="Box"> Grey 800
              </Box>
              <Box bg={({
                    theme
                  }) => theme.grey700} marginBottom={designTokens.space[2]} center color={({
                    theme
                  }) => theme.grey0} mdxType="Box"> Grey 700
              </Box>
            </div>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <div>
              <Box bg={({
                    theme
                  }) => theme.grey600} marginBottom={designTokens.space[2]} center color={({
                    theme
                  }) => theme.grey0} mdxType="Box"> Grey 600
              </Box>
              <Box bg={({
                    theme
                  }) => theme.grey500} marginBottom={designTokens.space[2]} center color={({
                    theme
                  }) => theme.grey0} mdxType="Box"> Grey 500
              </Box>
              <Box bg={({
                    theme
                  }) => theme.grey400} marginBottom={designTokens.space[2]} center mdxType="Box"> Grey 400
              </Box>
            </div>
          </Col>
          <Col col={1 / 3} media={{
                phone: 1
              }} mdxType="Col">
            <div>
              <Box bg={({
                    theme
                  }) => theme.grey300} marginBottom={designTokens.space[2]} center mdxType="Box"> Grey 300
              </Box>
              <Box bg={({
                    theme
                  }) => theme.grey200} marginBottom={designTokens.space[2]} center mdxType="Box"> Grey 200
              </Box>
              <Box bg={({
                    theme
                  }) => theme.grey100} marginBottom={designTokens.space[2]} center mdxType="Box"> Grey 100
              </Box>
              <Box bg={({
                    theme
                  }) => theme.grey0} marginBottom={designTokens.space[2]} center mdxType="Box"> Grey 0
              </Box>
            </div>
          </Col>
        </CSSGrid>
      </Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section section="typography" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col mdxType="Col">
        <h3>Typography</h3>
        <p>Typography is used to establish a balanced and intuitive interface. Scale, weight, and organization should be carefully considered when building UI.</p>
        <h5>Font</h5>
        <p>
          The typography in this portfolio is set in <strong>Inter</strong>, an awesome open-source typeface specifically designed for digital mediums. <a href="https://rsms.me/inter/" rel="noopener noreferrer">Read more about Inter!</a>
        </p>
        <Box color={({
              theme
            }) => theme.tertiaryDark} bg={({
              theme
            }) => theme.blueTransparent} mdxType="Box">
          <h6>Elements</h6>
          <h1>Header 1</h1>
          <h2>Header 2</h2>
          <h3>Header 3</h3>
          <h4>Header 4</h4>
          <h5>Header 5</h5>
          <h6>Header 6</h6>
          <p className="lead">This is a lead paragraph - often used as the first paragraph in a body of text and provide a bit more emphasis than a traditional paragraph.</p>
          <p>This is a normal <strong>paragraph</strong> and is the default for <i>multi-line</i> text.</p>
          <small>This is small text</small>
        </Box>
      </Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section section="components" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col mdxType="Col">
        <h3>Components</h3>
        <h5>Badge</h5>
        <Badge mdxType="Badge">Badge</Badge>
        <h5>Box</h5>
        <Box mdxType="Box">Box</Box>
        <h5>Button</h5>
        <Button bg={({
              theme
            }) => theme.highlightedText} color={({
              theme
            }) => theme.grey0} marginRight={designTokens.space[2]} mdxType="Button">Button</Button>
        <ButtonGhost color={({
              theme
            }) => theme.highlightedText} mdxType="ButtonGhost">Ghost Button</ButtonGhost>
        <h5>Card</h5>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            Card
          </CardBody>
        </CardContainer>
        <h5>List</h5>
        <List mdxType="List">
          <ListItem border mdxType="ListItem">
            <ListInner mdxType="ListInner">List Item #1</ListInner>
          </ListItem>
          <ListItem border mdxType="ListItem">
            <ListInner mdxType="ListInner">List Item #2</ListInner>
          </ListItem>
          <ListItem border mdxType="ListItem">
            <ListInner mdxType="ListInner">List Item #3</ListInner>
          </ListItem>
        </List>
        <h5>Pill</h5>
        <Pill mdxType="Pill">This is a Pill</Pill>
        <h5>Randomizer (fun facts)</h5>
        <RandomizerAbout mdxType="RandomizerAbout" />
      </Col>
    </CSSGrid>
  </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      